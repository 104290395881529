import classNames from 'classnames'
import { forwardRef, useEffect, useState } from 'react'

import AnimateOnScroll from '../../../../components/AnimateOnScroll/AnimateOnScroll'
import Heading from '../../../../components/Heading/Heading'
import Text from '../../../../components/Text/Text'
import withBackgroundColor from '../../../../components/withColor/withColor'
import { BasicN30 } from '../../../../constants/color.constants'
import { useViewport } from '../../../../contexts/Viewport.context'
import { useIsVisible } from '../../../../hooks/intersectionObserver.hook'

import styles from './Benefits.module.scss'

interface IBenefitsProps {
  canStartAnimation: boolean;
}

interface IBenefitsCollageProps extends IBenefitsProps {
  startAnimation: (value: boolean) => void;
}

const BENEFITS_DATA = {
  glass: {
    title: 'Date guaranteed',
    caption: 'Either the date will take place or the money will be returned',
  },
  diamond: {
    title: 'Respectful compensation',
    caption: 'Get honest compensation for your time, without facing exploitation and risks',
  },
  hand: {
    title: 'Transparency',
    caption: 'No bargaining, no hidden conditions - only honest and respectful relationship',
  },
}

const BENEFITS_COLLAGE_PHOTOS_SCALE = [
  'bikini',
  'center',
  'chips',
  'cocktail',
  'flowers',
  'ice',
  'left',
  'lips',
  'lipstick',
  'pizza',
  'right',
  'stone',
  'tower',
]

const BENEFITS_COLLAGE_PHOTOS_OPACITY = [
  'candy',
  'milkshake',
  'wine',
]

const BenefitsCards = ({ canStartAnimation }: IBenefitsProps) => (
  <div className={classNames(styles.cards, canStartAnimation && styles.appear)}>
    {Object.entries(BENEFITS_DATA).map(([key, value]) => (
      <AnimateOnScroll key={key} className={classNames('d-flex', styles.item, styles[key])}>
        <img
          src={`/assets/benefits-${key}.png`}
          alt={key.toLocaleUpperCase()}
        />
        <Heading as="h3" className={classNames('heading mobile-subheading2 desktop-subheading2', styles.title)}>
          {value.title}
        </Heading>
        <Text className={classNames('text2', styles.caption)}>
          {value.caption}
        </Text>
      </AnimateOnScroll>
    ))}
  </div>
)

const BenefitsCollage = ({ startAnimation, canStartAnimation }: IBenefitsCollageProps) => {
  const [isPlayed, setPlayed] = useState(false)
  const { elementRef, isVisible } = useIsVisible(0.9)

  useEffect(() => {
    if (isVisible) {
      startAnimation(true)
    }
  }, [startAnimation, isVisible])

  useEffect(() => {
    let timerId: NodeJS.Timeout

    if (canStartAnimation) {
      timerId = setTimeout(() => {
        setPlayed(true)
      }, 6000)
    }

    return () => {
      if (timerId) {
        clearTimeout(timerId)
      }
    }
  }, [canStartAnimation])

  if (isPlayed) {
    return null
  }

  return (
    <div className={classNames('d-flex-center', styles.collage, canStartAnimation && styles.trigger)} ref={elementRef}>
      <div className={styles.start}>
        <img src="/assets/collage/top.png" alt="diamond top" className={styles.top} />
        <img src="/assets/collage/diamond.png" alt="diamond" className={styles.diamond} />
      </div>
      <div className={styles.photos}>
        <div className={styles.scale}>
          {BENEFITS_COLLAGE_PHOTOS_SCALE.map((image) => (
            <img key={image} src={`/assets/collage/${image}.png`} alt={image} className={styles[image]} />
          ))}
        </div>
        <div className={styles.opacity}>
          {BENEFITS_COLLAGE_PHOTOS_OPACITY.map((image) => (
            <img key={image} src={`/assets/collage/${image}.png`} alt={image} className={styles[image]} />
          ))}
        </div>
      </div>
    </div>
  )
}

const Benefits = forwardRef((_, ref: React.LegacyRef<HTMLElement>) => {
  const { hasWidth } = useViewport()
  const [canStartAnimation, startAnimation] = useState(false)

  return (
    <section className={classNames('container d-flex-center', styles.main)} ref={ref}>
      <Heading as="h2" className={classNames('heading mobile-h2 desktop-h2', styles.heading)}>
        Reimagined dating experience
      </Heading>
      <BenefitsCards canStartAnimation={canStartAnimation} />
      {hasWidth && <BenefitsCollage startAnimation={startAnimation} canStartAnimation={canStartAnimation} />}
    </section>
  )
})

export default withBackgroundColor(BasicN30)(Benefits)
