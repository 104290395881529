import classNames from 'classnames'

import Heading from '../../../../components/Heading/Heading'
import Message from '../Message/Message'

import styles from './About.module.scss'

const ABOUT_MESSAGE_DATA = [
  'Find',
  { key: 'hearts' },
  'your',
  'perfect',
  'match',
  'on Synanti,',
  { key: 'vampire' },
  'where',
  { key: 'glass' },
  'respect',
  'and',
  'thoughtful',
  'gestures',
  'are',
  'valued.',
  { key: 'glasses' },
]

const About = () => (
  <section className={classNames('container d-flex', styles.main)}>
    <Heading as="h2" className={classNames('heading mobile-h2 desktop-h2', styles.title)}>
      What is Synanti?
    </Heading>
    <Message list={ABOUT_MESSAGE_DATA} />
  </section>
)

export default About
